import React, { useEffect, useState } from "react"
import Layout from "@components/layout"
import OnlyAuthorized from "@auth/OnlyAuthorized"
import { useAuth } from "@auth/useAuth"

import { Button, Card, CardBody, Col, Row } from "reactstrap"
import axios from "axios"

const itemsPerPage = 10

const BookingsPage = () => {
  const { makeRequest, isAuthenticated, hasScope } = useAuth() || {}

  const [reservations, setRes] = useState([])
  const [onDemand, setOnDemand] = useState([])
  const [dayPasses, setDayPasses] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pages, setPages] = useState(1)
  const [from, setFrom] = useState(0)
  const [to, setTo] = useState(itemsPerPage - 1)

  const [type, setType] = useState(0)

  const [searchString, setSearchString] = useState("")

  const fetchReservations = () => {
    makeRequest("list-reservations")
      .then((res) => {
        const onDemand = res.onDemand || []
        setPages(Math.ceil(onDemand.length / itemsPerPage))
        setTotal(onDemand.length)
        setOnDemand(onDemand)
        setRes(onDemand)

        setDayPasses(res.dayPasses.filter((dp) => !!dp.status) || [])
      })
      .catch((e) => console.log(e))
  }

  useEffect(() => {
    if (!isAuthenticated || !hasScope("create:space-operators")) return
    fetchReservations()
  }, [isAuthenticated])

  const inc = () => {
    if (page >= pages) return
    setPage(page + 1)
  }

  const dec = () => {
    if (page <= 1) return
    setPage(page - 1)
  }

  useEffect(() => {
    if (type === 0) {
      const bookings = onDemand.filter((booking) =>
        booking?.space_name?.toLowerCase().includes(searchString.toLowerCase())
      )
      setRes(bookings)
      setPages(Math.ceil(bookings.length / itemsPerPage))
      setTotal(bookings.length)
    } else if (type === 1) {
      const bookings = dayPasses.filter((booking) => {
        const valid = booking?.space_name
          ?.toLowerCase()
          .includes(searchString.toLowerCase())
        return valid
      })
      setRes(bookings)
      setPages(Math.ceil(bookings.length / itemsPerPage))
      setTotal(bookings.length)
    }
    setPage(1)
  }, [type, searchString])

  useEffect(() => {
    setFrom((page - 1) * itemsPerPage)
    setTo(itemsPerPage * page > total ? total : itemsPerPage * page)
  }, [page, pages, total])

  const [cancellationProgress, setCancellationProgress] = useState(false)

  const cancelReservation = async (reservation = {}) => {
    if (!reservation.id || !isAuthenticated) return

    if (reservation.ws_mvp_reservation_id) {
      try {
        await axios.post(
          `${process.env.GATSBY_MVP_API_URL}/reservations/cancel`,
          {
            id: reservation.ws_mvp_reservation_id,
          }
        )
      } catch (e) {
        console.log(e)
      }
    }

    setCancellationProgress(true)
    const resType = type === 1 ? "day-pass" : "office"
    await makeRequest("cancel-reservation", {
      id: reservation.id,
      type: resType,
    }).then((res) => {
      fetchReservations()
    })
    setCancellationProgress(false)
  }

  return (
    <Layout title={"Bookings"}>
      <OnlyAuthorized permission={"manage:space-providers"}>
        <Card className={"dashboard-card"}>
          <CardBody>
            <div className="d-flex align-items-center mb-3 px-4">
              <p className={"m-0 mr-2"}>
                <strong>Type:</strong>
              </p>
              <Button
                size={"sm"}
                color={type === 0 ? "primary" : "default"}
                onClick={() => setType(0)}
              >
                On Demand
              </Button>{" "}
              <Button
                size={"sm"}
                color={type === 1 ? "primary" : "default"}
                onClick={() => setType(1)}
              >
                Day Passes
              </Button>
              <div className={"ml-auto"}>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"Location Name"}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                />
              </div>
            </div>
            <div className="card-inner">
              <table
                className={`admin-bookings-table table ${
                  reservations.length === 0 ? "" : "table-hover"
                }`}
              >
                <thead>
                  <tr>
                    <th>Location</th>
                    <th className={"text-center"}>From</th>
                    <th className={"text-center"}>
                      {type === 0 ? "To" : "Number of passes"}
                    </th>
                    <th className={"text-right"}>Service Fee</th>
                    <th className={"text-right"}>Total</th>
                    <th className={"text-center"}>Status</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {reservations.length === 0 ? (
                    <tr>
                      <td className={"text-center"} colSpan={"5"}>
                        Loading results...
                      </td>
                    </tr>
                  ) : (
                    reservations.slice(from, to).map((res, idx) => {
                      let status = (
                        <Badge text={"Payment pending"} type={"info"} />
                      )

                      let isPending = false

                      if (res?.manual_payment) {
                        status = (
                          <Badge
                            text={"Manual Collection"}
                            type={"secondary"}
                          />
                        )
                      } else {
                        if (type === 0) {
                          if (res.payment_complete) {
                            status = (
                              <Badge
                                text={"Complete"}
                                type={"success"}
                                link={res.invoice_url}
                              />
                            )
                          } else if (res.status === "cancelled") {
                            status = (
                              <Badge text={"Cancelled"} type={"secondary"} />
                            )
                          } else {
                            isPending = true
                          }
                        } else if (type === 1) {
                          if (res.status === 2) {
                            status = (
                              <Badge
                                text={"Complete"}
                                type={"success"}
                                link={res?.invoice_url}
                              />
                            )
                          } else if (res.status === 3) {
                            status = (
                              <Badge text={"Cancelled"} type={"secondary"} />
                            )
                          } else {
                            isPending = true
                          }
                        }
                      }

                      return (
                        <tr key={idx}>
                          <td>
                            {res.pdf_url ? (
                              <a
                                href={res.pdf_url}
                                rel={"noreferrer"}
                                target={"_blank"}
                              >
                                {res.space_name}
                              </a>
                            ) : (
                              res.space_name
                            )}
                          </td>
                          <td className={"text-center"}>
                            {res.date && res.date.split("T")[0]}{" "}
                            {res.arrival_time}
                          </td>
                          <td className={"text-center"}>
                            {type === 0 ? (
                              <>
                                {res.end_date
                                  ? res.end_date.split("T")[0]
                                  : "-"}{" "}
                                {res.departure_time}
                              </>
                            ) : (
                              res.number_of_passes
                            )}
                          </td>
                          <td className={"text-right"}>
                            {Number(res.service_fee / 100).toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                              }
                            )}
                          </td>
                          <td className={"text-right"}>
                            {Number(res.total / 100).toLocaleString("en-US", {
                              style: "currency",
                              currency: "USD",
                            })}
                          </td>

                          <td className={"text-center"}>{status}</td>

                          <td className={"text-center"}>
                            {isPending && (
                              <button
                                className="clear-button-styles text-danger ml-2"
                                onClick={() => cancelReservation(res)}
                                disabled={cancellationProgress}
                              >
                                Cancel
                              </button>
                            )}
                          </td>
                        </tr>
                      )
                    })
                  )}
                </tbody>
              </table>

              {reservations.length > 0 && (
                <Row>
                  <Col>
                    <span>
                      Displaying {from + 1} - {to} of {reservations.length}
                    </span>
                  </Col>
                  <Col className={"text-right"}>
                    <button
                      className={"btn btn-secondary btn-sm ml-2"}
                      onClick={dec}
                      disabled={page <= 1}
                    >
                      Prev
                    </button>
                    <button
                      className={"btn btn-secondary btn-sm ml-2"}
                      onClick={inc}
                      disabled={page >= pages}
                    >
                      Next
                    </button>
                  </Col>
                </Row>
              )}
            </div>
          </CardBody>
        </Card>
      </OnlyAuthorized>
    </Layout>
  )
}

const Badge = ({ text = "Complete", type = "primary", link = null }) => {
  return (
    <>
      <span className={`badge badge-pill badge-${type}`}>{text}</span>
      {link && (
        <a
          href={link}
          target={"_blank"}
          rel={"noreferrer"}
          className={"d-inline-block ml-2"}
        >
          View invoice
        </a>
      )}
    </>
  )
}

export default BookingsPage
